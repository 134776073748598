import React from "react";

const TermDescription = (props) => {
  if (typeof props.terms === "undefined"
    ||typeof props.terms.single === "undefined"
    || !props.terms.single.description
    || props.terms.single.description.length === 0) {
    return (<></>);
  }
  const options = JSON.parse(domNode.children[0].data);
  const className = options.textAlign ? "wp-block-term-description has-text-align-"+options.textAlign : undefined;
  return <p className={ className }>{ props.terms.single.description }</p>;
};

export default TermDescription;
